<template>
    <main>
        <HeaderTab :title="$t('global.gestion_planning')" />

        <div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<CustomTable
								ref="table"
								id_table="task_planning_setting"
								:busy.sync="table_busy"
								primaryKey="taskplanning_id"
								:hide_if_empty="true"
								:items="planning"
							/>
                		</div>
                	</div>
                </div>
            </div>
        </div>

        <b-modal ref="modal" hide-footer>
            <template v-slot:modal-title>
                <template v-if="planning_edit.taskplanning_id">
                    {{ $t("global.modifier") }}
                </template>

                <template v-else>
                    {{ $t("global.ajouter") }}
                </template>
            </template>

            <div>
				<label>{{ $t("task_planning.label") }} *</label>
				<b-input type="text" class="form-control" v-model="planning_edit.taskplanning_label"/>

				<label class="mt-3">{{ $t('task_planning.lieux') }} *</label>
				<e-select
                    v-model="planning_edit.lieux"
                    id="mouv_resid"
                    track-by="uuid"
                    label="name"
					:placeholder="$t('task_planning.select_lieux')"
					multiple
                    :options="lieux"
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="false"
                >
					<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.name }}</span></template>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                </e-select>

                <label class="mt-3">{{ $t('task_planning.actes_types') }} *</label>
                <e-select
					v-model="planning_edit.actes_types"
					id="actes_types"
					track-by="name"
					label="name"
					:placeholder="$t('task_planning.select_actes_types')"
					:options="actes_types"
					:searchable="true"
					:show-labels="false"
					:allow-empty="false"
					group-values="actes" 
					group-label="groupe" 
					:group-select="false"
					multiple
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>

				<label class="mt-3">{{ $t('task_planning.contacts') }} *</label>
				<e-select
                    v-model="planning_edit.contacts"
                    id="contact_id"
                    track-by="contact_id"
                    label="contact_label"
					:placeholder="$t('task_planning.select_contacts')"
                    :options="contacts"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    :group-select="false"
					multiple
                />

				<label class="mt-3">{{ $t('task_planning.group_by') }}</label>
				<e-select
                    v-model="selected_groupby"
                    id="group_by"
                    track-by="groupby_id"
                    label="groupby_label"
					:placeholder="$t('task_planning.select_group_by')"
                    :options="group_bys"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :group-select="false"
					:allowEmpty="false"
                />
            </div>

            <ErrorAlert v-if="show_error" class="mt-3" :messageI18n="'formulaire.erreur_champs_non_remplis'" />

			<div class="text-center">
				<b-button v-if="planning_edit.taskplanning_id"  pill variant="primary" class="mt-3" @click.prevent="saveForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'edit']" /> {{ $t("global.modifier") }}</b-button>
	            <b-button v-else  pill variant="primary" class="mt-3" @click.prevent="saveForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t("global.ajouter") }}</b-button>
	        </div>
        </b-modal>
    </main>
</template>

<script type="text/javascript">
import TaskPlanning from "@/mixins/TaskPlanning.js"
import IntraLocation from "@/mixins/IntraLocation.js"
import Actes from "@/mixins/Actes.js"
import Contact from "@/mixins/Contact.js"
import Navigation from "@/mixins/Navigation.js"
import _cloneDeep from 'lodash/cloneDeep'

export default {
	name: 'TaskPlanningSetting',
	mixins: [TaskPlanning, IntraLocation, Actes, Contact, Navigation],
	data () {
		return {
			table_busy: false,
			planning: [],
			events_tab: {
				'TableAction::goToAddTaskPlanning': this.openModalAddPlanning,
				'TableAction::goToEditTaskPlanning': this.openModalEditPlanning,
				'TableAction::goToArchiveTaskPlanning': (taskplannings) => {
					let taskplanning_ids = taskplannings.map((taskplanning) => taskplanning.taskplanning_id)
					this.archiveTaskPlanning(taskplanning_ids, !taskplannings[0].taskplanning_archive)
						.then(() => {
							this.init_component()
						})
				}
			},
			planning_edit: {
				taskplanning_id: null,
				taskplanning_label: '',
				taskplanning_groupby: null,
				lieux: [],
				actes_types: [],
				contacts: []
			},
			lieux: [],
			actes_types: [],
			contacts: [],
			show_error: false,
			processing: false,
			group_bys: [],
			selected_groupby: null
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			this.table_busy = true
			const planning = await this.getAllTaskPlanning(false)
			this.group_bys = this.getTaskPlanningGroupBy()
			this.selected_groupby = this.group_bys.find(gb => gb.groupby_id == 1)
			this.formatPlanning(planning)
			this.table_busy = false

			this.loadLieux()
			this.loadActeTypes()
			this.contacts = await this.getAllContact(true)
		},

		formatPlanning(planning) {
			planning.forEach(plan => {
				plan.lieux_label = plan.lieu.map(lieu => {
					return lieu.taskplanninglieu_lieu ? lieu.lieu.lieu_label : lieu.intra_location.intralocation_label
				}).join(', ')

				const actes_type = plan.actes_type.filter(acte_type => acte_type.actes_type.active == null || acte_type.actes_type.active.actestypeactive_active)

				plan.actes_types_label = actes_type.map(acte_type => {
					return this.checkIfTranslationExists('actes_type.'+acte_type.actes_type.actestype_code) ? this.$t('actes_type.'+acte_type.actes_type.actestype_code) : acte_type.actes_type.actestype_label
				}).join(', ')

				plan.contacts_label = plan.contact.map(contact => {
					return contact.contact.contact_firstname+' '+contact.contact.contact_lastname
				}).join(', ')


				plan.lieux = plan.lieu.map(lieu => {
					return  {
						id: lieu.taskplanninglieu_lieu ? lieu.lieu.lieu_id : lieu.intra_location.intralocation_id,
						is_lieu: lieu.taskplanninglieu_lieu !== null
					}
				})

				plan.actes_types = plan.actes_type.map(acte_type => acte_type.actes_type.actestype_id)

				plan.contacts = plan.contact.map(contact => contact.contact.contact_id)

				plan.taskplanning_archive = plan.taskplanning_archive == 1
			})

			this.planning = planning
		},

		checkIfTranslationExists(string) {
			return !(string === this.$t(string))
		},

		async loadLieux() {
			const lieux = await this.getIntraLocationsWithresidences(true, false) || []
			this.lieux = []
			let inc = 0
			
			lieux.forEach(lieu => {
				const temp = {
					id: lieu.intralocation_id,
					uuid: inc++,
					name: lieu.intralocation_label,
					indent: lieu.indent,
					is_lieu: false,
					default: lieu.intralocation_default
				}

				// Si c'est un lieu ou une localisation interne
				if(lieu.indent == 0) {
					temp.is_lieu = true
				}
				
				this.lieux.push(temp)
			})
		},

		async loadActeTypes() {
			let actes_types = await this.getActesTypes()
			actes_types = actes_types.filter(acte_type => acte_type.actestype_label !== "---" && acte_type.active)

			let actes_tab = {}
			for (let i = 0; i < actes_types.length; i++) {
				if(!Object.prototype.hasOwnProperty.call(actes_tab, actes_types[i].actestype_codegroupe)){
					actes_tab[actes_types[i].actestype_codegroupe] = []
				}
				actes_tab[actes_types[i].actestype_codegroupe].push({
					id: actes_types[i].actestype_id,
					name: actes_types[i].actestype_label,
				})
			}

			let res = []
			for(let groupe in actes_tab) {
				res.push({
					groupe: this.$t('actes_groupe.' + groupe),
					actes: actes_tab[groupe]
				})
			}

			this.actes_types = res
		},

		openModalAddPlanning() {
			this.planning_edit = {}
			this.$refs.modal.show()
		},

		openModalEditPlanning(planning) {
			let lieux = []
			planning.lieux.forEach(lieu => {
				lieux.push(this.lieux.find(l => lieu.id == l.id && lieu.is_lieu == l.is_lieu))
			})

			let all_actes_types = []
			this.actes_types.forEach(type => {
				all_actes_types = all_actes_types.concat(type.actes)
			})
			const actes_types = all_actes_types.filter(type => planning.actes_types.includes(type.id))

			this.selected_groupby = this.group_bys.find(groupby => groupby.groupby_id == planning.taskplanning_groupby)

			this.planning_edit = {
				taskplanning_id: planning.taskplanning_id,
				taskplanning_label: planning.taskplanning_label,
				lieux: lieux,
				actes_types: actes_types,
				group_by: planning.taskplanning_groupby,
				contacts: this.contacts.filter(contact => planning.contacts.includes(contact.contact_id))
			}
			this.$refs.modal.show()
		},

		async saveForm() {
			this.show_error = false

			if(this.planning_edit.taskplanning_label == '' || !this.planning_edit.lieux || this.planning_edit.lieux.length == 0 || !this.planning_edit.actes_types || this.planning_edit.actes_types.length == 0 || !this.planning_edit.contacts || this.planning_edit.contacts.length == 0) {
				this.show_error = true
				return false
			}

			this.processing = true
			
			let params = _cloneDeep(this.planning_edit)
			params.taskplanning_groupby = this.selected_groupby ? this.selected_groupby.groupby_id : null
			params.lieux = this.planning_edit.lieux.map(lieu => ({id: lieu.id, is_lieu: lieu.is_lieu}))
			params.actes_types = this.planning_edit.actes_types.map(acte_type => acte_type.id)
			params.contacts = this.planning_edit.contacts.map(contact => contact.contact_id)

			if(this.planning_edit.taskplanning_id) {
				await this.updateTaskPlanning(this.planning_edit.taskplanning_id, params)
			}
			else {
				await this.createTaskPlanning(params)
			}

			this.processing = false
			this.planning_edit = {
				taskplanning_id: null,
				taskplanning_label: '',
				taskplanning_groupby: null,
				lieux: [],
				actes_types: [],
				contacts: []
			}
			this.$refs.modal.hide()
			this.$refs.table.unselectAll()
			this.init_component()
		}
	},

	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert')
	}
}

</script>